import usePartySocket from "partysocket/react";
import { useState } from "react";
import type { TacklePartyState, TacklePartyAction } from "../../shared/logic";

export const usePartyRoom = (username: string, roomId: string) => {
  const [partyState, setPartyState] = useState<TacklePartyState | null>(null);

  const socket = usePartySocket({
    host: "127.0.0.1:1999" || "https://tackle-party-demo.jecoms.partykit.dev",
    room: roomId,
    id: username,
    onMessage(event: MessageEvent<string>) {
      setPartyState(JSON.parse(event.data));
    },
  });

  const dispatch = (action: TacklePartyAction) => {
    socket.send(JSON.stringify(action));
  };

  return {
    partyState,
    dispatch,
  };
};
