import { faker } from "@faker-js/faker";

export interface User {
  id: string;
  vendorId: string;
  name: string;
  location: string;
  cursor: {
    x: number;
    y: number;
  };
}

export const emptyUser: User = {
  id: "",
  vendorId: "",
  name: "",
  location: "",
  cursor: { x: 0, y: 0 },
};

interface BasePartyState {
  partyMembers: {
    [userId: User["id"]]: User;
  };
}

export type TacklePartyAction = DefaultAction | PartyAction;

export type PartyServerAction = WithUser<DefaultAction> | WithUser<PartyAction>;

type WithUser<T> = T & { user: Partial<User> };

export type DefaultAction = { type: "grow-party-size"; user: User };

export interface TacklePartyState extends BasePartyState {
  fishInTank: Fish[];
  fishKillPoints: {
    // using UTC hours as keys
    [time: CurrentHourStamp]: {
      [userId: User["id"]]: number;
    };
  };
}

export const initialParty = (): TacklePartyState => ({
  partyMembers: {},
  fishInTank: [],
  fishKillPoints: {},
});

type CurrentHourStamp = string;

export const getCurrentHour = (): CurrentHourStamp => {
  const now = new Date();
  return `${now.getUTCFullYear()}-${
    now.getUTCMonth() + 1
  }-${now.getUTCDate()}T${now.getUTCHours()}:00:00.000Z`;
};

export interface Fish {
  id: string;
  name: string;
  image: string;
  value: number;
  x: number;
  y: number;
  deathTs: number;
  height?: number;
  width?: number;
}

const spawnNewFish = (): Fish => {
  const fishTypeValue = Math.random();

  switch (true) {
    case fishTypeValue < 0.05:
      return {
        id: faker.string.uuid(),
        name: `${faker.hacker.adjective()} ${faker.hacker.noun()} puffer`,
        image: "/images/puffer.png",
        height: 50,
        width: 50,
        x: Math.random(),
        y: Math.random(),
        value: -3,
        deathTs: new Date().getTime() + 1000 * 10,
      };
    case fishTypeValue > 0.85 && fishTypeValue < 0.995:
      return {
        id: faker.string.uuid(),
        name: `${faker.hacker.adjective()} ${faker.hacker.noun()} mackerel`,
        image:
          Math.random() < 0.5
            ? "/images/mackerel.png"
            : "/images/mackerel-mirrored.png",
        height: 45,
        width: 85,
        x: Math.random(),
        y: Math.random(),
        value: 5,
        deathTs: new Date().getTime() + 1000 * 5,
      };
    case fishTypeValue >= 0.995 && fishTypeValue < 0.9995:
      return {
        id: faker.string.uuid(),
        name: `${faker.hacker.adjective()} ${faker.hacker.noun()} whale`,
        image:
          Math.random() < 0.5
            ? "/images/whale.png"
            : "/images/whale-mirrored.png",
        height: 80,
        width: 160,
        x: Math.random(),
        y: Math.random(),
        value: 50,
        deathTs: new Date().getTime() + 1000 * 20,
      };
    case fishTypeValue >= 0.9995:
      return {
        id: faker.string.uuid(),
        name: `Nemo`,
        image: "/images/clown-mirrored.png",
        height: 25,
        width: 25,
        x: Math.random(),
        y: Math.random(),
        value: 101,
        deathTs: new Date().getTime() + 1000 * 20,
      };
    default:
      return {
        id: faker.string.uuid(),
        name: `${faker.hacker.adjective()} ${faker.hacker.noun()} clown`,
        image: "/images/clown.png",
        height: 50,
        width: 50,
        x: Math.random(),
        y: Math.random(),
        value: 1,
        deathTs: new Date().getTime() + 1000 * 20,
      };
  }
};

export const MAX_FISH_PER_PARTIER = 3;

type PartyAction =
  | { type: "update-user-cursor"; userId: User["id"]; cursor: User["cursor"] }
  | { type: "visit-url" }
  | { type: "spawn-fish"; userId: User["id"] }
  | { type: "hook-fish"; userId: User["id"]; fishId: Fish["id"] }
  | { type: "update-location"; userId: User["id"]; location: User["location"] }
  | { type: "shrink-party-size"; userId: User["id"] };

export const partyUpdater = (
  action: TacklePartyAction,
  state: TacklePartyState
): TacklePartyState => {
  switch (action.type) {
    case "grow-party-size":
      return {
        ...state,
        partyMembers: Object.fromEntries([
          [action.user.id, action.user],
          ...Object.entries(state.partyMembers),
        ]),
      };
    case "shrink-party-size":
      return {
        ...state,
        partyMembers: Object.fromEntries(
          Object.entries(state.partyMembers).filter(
            ([id, _], __) => id !== action.userId
          )
        ),
      };
    case "update-location":
      return {
        ...state,
        partyMembers: {
          ...state.partyMembers,
          [action.userId]: {
            ...state.partyMembers[action.userId],
            location: action.location,
          },
        },
      };
    case "update-user-cursor":
      return {
        ...state,
        partyMembers: {
          ...state.partyMembers,
          [action.userId]: {
            ...state.partyMembers[action.userId],
            cursor: action.cursor,
          },
        },
      };
    case "spawn-fish":
      if (
        Object.keys(state.partyMembers).length * MAX_FISH_PER_PARTIER <=
        state.fishInTank.length
      ) {
        return state;
      }

      return {
        ...state,
        fishInTank: [
          ...state.fishInTank.filter(
            ({ deathTs }) => deathTs > new Date().getTime()
          ),
          spawnNewFish(),
        ],
      };
    case "hook-fish":
      const fishFound = state.fishInTank.find((f) => f.id === action.fishId);
      if (!fishFound) {
        return state;
      }
      const currentHour = getCurrentHour();

      return {
        ...state,
        fishInTank: state.fishInTank.filter((f) => f.id !== action.fishId),
        fishKillPoints: {
          ...state.fishKillPoints,
          [currentHour]: {
            ...state.fishKillPoints[currentHour],
            [action.userId]:
              (state.fishKillPoints[currentHour]?.[action.userId] ?? 0) +
              fishFound.value,
          },
        },
      };
    default:
      return state;
  }
};
