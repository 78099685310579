import { useEffect, useRef, useState } from "react";
import { usePartyRoom } from "./hooks/usePartyRoom";

import { faker } from "@faker-js/faker";
import {
  getCurrentHour,
  MAX_FISH_PER_PARTIER,
  type Fish,
  type TacklePartyAction,
  type TacklePartyState,
} from "../shared/logic";

interface PartyProps {
  username: string;
  roomId: string;
}

export const stringToColor = (value: string) => {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, 85%, 35%)`;
};

function getContrastTextColor(backgroundColor: string): string {
  // Convert the background color to RGB
  const rgb = parseInt(backgroundColor.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  // Calculate the relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return black for bright colors and white for dark colors
  // todo pick more fun colors
  return luminance > 128 ? "black" : "white";
}

const Party = ({ username, roomId }: PartyProps) => {
  const { partyState, dispatch } = usePartyRoom(username, roomId);
  const cursorRef = useRef<HTMLDivElement>(null);

  // hack everyone to fish tank location
  useEffect(() => {
    dispatch({
      type: "update-location",
      userId: username,
      location: "/Fish Tank",
    });
  }, []); // eslint-disable-line

  const [userCursor, setUserCursor] = useState({ x: 0, y: 0 });

  // track/share mouse movement
  useEffect(() => {
    const handleMouseMove = ({ clientX: x, clientY: y }: MouseEvent) => {
      if (x === userCursor.x && y === userCursor.y) {
        return;
      }

      const xRelativeToWindow = (x - 15) / window.innerWidth;
      setUserCursor({ x: xRelativeToWindow, y });
      dispatch({
        type: "update-user-cursor",
        userId: username,
        cursor: { x: xRelativeToWindow, y },
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); //eslint-disable-line

  // fish spawner
  useEffect(() => {
    const spawnFish = () => {
      const fish = partyState?.fishInTank;
      const partyMembers = partyState?.partyMembers;

      if (!fish || !partyMembers) {
        return;
      }

      const shouldSpawnFish = Math.random() < 0.015;

      if (
        shouldSpawnFish &&
        fish.length < Object.keys(partyMembers).length * MAX_FISH_PER_PARTIER
      ) {
        dispatch({
          type: "spawn-fish",
          userId: username,
        });
      }
    };

    spawnFish();
    const intervalId = setInterval(spawnFish, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, username]); // eslint-disable-line

  if (partyState === null) {
    return (
      <p>
        <span className="transition-all w-fit inline-block mr-4 animate-bounce">
          🎣
        </span>
        Waiting for server...
      </p>
    );
  }

  const currentHour = getCurrentHour();
  const sortedHourlyLeaderboard = Object.entries(
    partyState.fishKillPoints?.[currentHour] ?? {}
  ).sort(([_, scoreA], [__, scoreB]) => {
    return scoreB - scoreA;
  });

  const today = currentHour.split("T")[0];
  const sortedDailyLeaderboard = Object.entries(
    Object.entries(partyState?.fishKillPoints ?? {})
      .filter(([hour]) => hour.startsWith(today))
      .reduce((acc, [_, hourlyLeaderboard]) => {
        for (const [id, score] of Object.entries(hourlyLeaderboard)) {
          acc[id] = (acc[id] || 0) + score;
        }

        return acc;
      }, {} as Record<string, number>)
  ).sort(([_, scoreA], [__, scoreB]) => {
    return scoreB - scoreA;
  });

  return (
    <div>
      <h1 className="text-2xl border-b border-gray-400 text-center relative">
        🎣 Tackle Party 🎣 [{roomId}]
      </h1>
      <section>
        <FishTank
          username={username}
          dispatch={dispatch}
          fishes={partyState.fishInTank}
        />
      </section>
      <section>
        <h3 className="text-md">Scoreboards</h3>
        <div className="flex min-h-[200px] max-h-[200px] overflow-y-auto">
          <div className="flex-1 border p-4">
            <h2>Hourly Leaderboard</h2>
            <hr />
            <ol>
              {sortedHourlyLeaderboard.map(([id, score], i) => (
                <li key={id}>
                  {i + 1}. {id}: {score}
                </li>
              ))}
            </ol>
          </div>
          <div className="flex-1 border p-4">
            <h2>Daily Leaderboard</h2>
            <hr />
            <ol>
              {sortedDailyLeaderboard.map(([id, score], i) => (
                <li key={id}>
                  {i + 1}. {id}: {score}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </section>
      <div className="relative" ref={cursorRef}>
        {Object.entries(partyState.partyMembers)
          .filter(
            ([id, user]) =>
              id !== username &&
              user.location === partyState.partyMembers[username].location
          )
          .map(([id, member]) => {
            const x = member.cursor.x * window.innerWidth;
            const y = member.cursor.y - (cursorRef?.current?.offsetTop || 0);

            return (
              <div
                key={id}
                style={{
                  left: x,
                  top: y,
                }}
                className="absolute"
              >
                <p className="" title={member.id}>
                  🪝
                  <div
                    className="absolute rounded-full p-1 text-xs"
                    style={{
                      left: "100%",
                      top: "100%",
                      backgroundColor: stringToColor(member.id + roomId),
                      color: getContrastTextColor(
                        stringToColor(member.id + roomId)
                      ),
                    }}
                  >
                    {member.id}
                  </div>
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

interface FishTankProps {
  username: string;
  dispatch: (action: TacklePartyAction) => void;
  fishes: Fish[];
}

const FishTank = ({ username, dispatch, fishes }: FishTankProps) => {
  const [isMuted, setIsMuted] = useState(true);
  const [playingHorn, setPlayingHorn] = useState(false);
  const tankRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      fishes.length > 0 &&
      fishes.every((fish) => fish.value < 0) &&
      !isMuted
    ) {
      if (!playingHorn) {
        setPlayingHorn(true);
        const hornSoundEffect = new Audio("/sounds/horn.mp3");
        hornSoundEffect.volume = 0.05;
        hornSoundEffect.play();
      }
    } else if (playingHorn && fishes.some((fish) => fish.value > 0)) {
      setPlayingHorn(false);
    }
  }, [fishes, isMuted, playingHorn, setPlayingHorn]);

  const handleFishHook = (fishId: Fish["id"], fishValue: Fish["value"]) => {
    if (!isMuted) {
      // Create a new Audio object and play the sound
      let soundEffect: HTMLAudioElement;
      switch (true) {
        case fishValue > 100:
          soundEffect = new Audio("/sounds/nemo.mp3");
          break;
        case fishValue > 10:
          soundEffect = new Audio("/sounds/wow.mp3");
          break;
        case fishValue > 1:
          soundEffect = new Audio("/sounds/nice.mp3");
          break;
        case fishValue < 0:
          soundEffect = new Audio("/sounds/scream.mp3");
          break;
        default:
          soundEffect = new Audio("/sounds/splash.mp3");
      }
      soundEffect.volume = 0.05;
      soundEffect.play();
    }

    dispatch({
      type: "hook-fish",
      userId: username,
      fishId: fishId,
    });
  };

  return (
    <div
      ref={tankRef}
      style={{
        backgroundImage: `url(/images/ocean2.png)`,
        cursor: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🪝</text></svg>") 16 0,auto`,
      }}
      className="bg-blue-100 p-4 rounded text-sm min-h-[600px] max-h-[600px] bg-cover bg-center relative"
    >
      <button
        onClick={() => setIsMuted(!isMuted)}
        className="absolute top-0 right-0 m-4"
        title="Toggle sound effects"
      >
        {isMuted ? <span>🔇</span> : <span>🔊</span>}
      </button>

      {fishes.map((fish, i) => {
        return (
          <div
            key={fish.id}
            className="absolute inline-block"
            style={{
              left: fish.x * window.innerWidth,
              top: fish.y * 575,
              zIndex: 10001,
            }}
            onClick={() => handleFishHook(fish.id, fish.value)}
          >
            <img
              src={fish.image}
              alt={fish.name}
              title={fish.name}
              width={fish?.width || 40}
              height={fish?.height || 40}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Party;
