import { faker } from "@faker-js/faker";
import Party from "./Party";
import "../styles.css";

import { createRoot } from "react-dom/client";

function App() {
  const username = faker.internet.userName();
  const roomId = "tackle-party";

  return (
    <main>
      <Party username={username} roomId={roomId} />
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
